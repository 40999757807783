export * from "shared/build/src/loan/LoanFunctions";

import { config } from "shared/build/src/utils/ConfigValues";
import { LTVDangerLevels } from "shared/build/src/loan/LoanFunctions";

type LTVTailwindClassesLeaf = {
  text:
    | "text-danger fill-danger"
    | "text-warning fill-warning"
    | "text-success fill-success";
  background: "bg-danger" | "bg-warning" | "bg-success";
};

type LTVTailwindClasses = {
  [key: string]: LTVTailwindClassesLeaf;
};

const LTVClasses = {
  LIQUIDATED: { text: "text-danger fill-danger", background: "bg-danger" },
  CRITICAL: { text: "text-danger fill-danger", background: "bg-danger" },
  POOR: { text: "text-warning fill-warning", background: "bg-warning" },
  FAIR: { text: "text-warning fill-warning", background: "bg-warning" },
  HEALTHY: { text: "text-success fill-success", background: "bg-success" },
} satisfies LTVTailwindClasses;

export function getLTVTextColor(dangerLevel: LTVDangerLevels) {
  return LTVClasses[dangerLevel].text;
}

export function getLTVBackgroundColor(dangerLevel: LTVDangerLevels) {
  return LTVClasses[dangerLevel].background;
}

export function getLoanCalculatorConfigValues() {
  const loanAmountCharLimit = parseInt(
    config("LOAN_CALC_LOAN_AMOUNT_CHAR_LIMIT", "7"),
    10
  );
  const loanAmountValueLimit = parseInt(
    config("LOAN_CALC_LOAN_AMOUNT_VALUE_LIMIT", "1000000"),
    10
  );
  const collateralCharLimit = parseInt(
    config("LOAN_CALC_COLLATERAL_CHAR_LIMIT", "17"),
    10
  );

  return {
    loanAmountCharLimit,
    loanAmountValueLimit,
    collateralCharLimit,
  };
}

export function getInternalAdapterPackageName(name: string) {
  return name === "ETH-B" || name === "WBTC-B"
    ? "High"
    : name === "ETH-A" || name === "WBTC-A"
    ? "Mid"
    : "Low";
}
