"server only";
import { BigFloat } from "../utils/BigFloat";
import { LoanStatus } from "./Model";
export function getYearlyInterestRate(rate) {
    return rate
        .pow(60)
        .toDecimals(27)
        .pow(60)
        .toDecimals(27)
        .pow(24)
        .toDecimals(27)
        .pow(365)
        .toDecimals(27)
        .sub(new BigFloat(BigInt(1), 0))
        .mul(new BigFloat(BigInt(100), 0));
}
export function getYearlyInterestRateWithVanirFee(rate) {
    const vanirFee = BigFloat.fromNumber(2.5);
    return getYearlyInterestRate(rate).add(vanirFee);
}
export function getLoanDetails(loan) {
    const interestRate = getYearlyInterestRate(loan.adapter.secondlyRate);
    const loanName = loan.adapter.name;
    const collateral = loan.collateral;
    const collateralName = loan.adapter.assetId;
    const totalDebt = getTotalDebt(loan);
    const collateralValue = collateral.mul(loan.adapter.price);
    const ltv = totalDebt.div(collateralValue);
    const ltvLimit = loan.adapter.liquidationTreshold;
    const availableExtraLoanAmount = getAvailableExtraLoanAmountForLoan(loan);
    const state = getLoanState(ltv, loan.status, loan.adapter.liquidationTreshold);
    return {
        loanId: loan.id,
        loanName,
        collateral,
        totalDebt,
        interestRate,
        ltv,
        ltvLimit,
        collateralName,
        availableExtraLoanAmount,
        state,
    };
}
export function getTotalDebt(loan) {
    if (loan.active) {
        return loan.normalizedDebt.mul(loan.adapter.rate);
    }
    else {
        return loan.loanAmount;
    }
}
export function getLtv(args) {
    const { debt, collateral, price } = args;
    const collateralValue = collateral.mul(price);
    const ltv = debt.div(collateralValue);
    return ltv;
}
export function getCollateralFromLtv(args) {
    const { ltv, loanAmount, price } = args;
    const collateralValue = loanAmount.div(ltv);
    const collateral = collateralValue.div(price);
    return collateral;
}
export function getLoanAmountFromLtv(args) {
    const { ltv, collateral, price } = args;
    const collateralValue = collateral.mul(price);
    const loanAmount = ltv.mul(collateralValue);
    return loanAmount;
}
export function getAvailableExtraLoanAmountForLoan(loan) {
    const collateralValue = loan.collateral.mul(loan.adapter.price);
    const ltvLimit = getLtvValues(loan.adapter.liquidationTreshold).POOR;
    const maxLoanAmount = collateralValue.mul(ltvLimit);
    const availableExtraLoanAmount = maxLoanAmount.sub(getTotalDebt(loan));
    return availableExtraLoanAmount;
}
export function getDangerLevelForLtv(ltv, liquidationThreshold) {
    const level = getLtvValues(liquidationThreshold);
    if (ltv.greaterThan(level.LIQUIDATED))
        return "LIQUIDATED";
    if (ltv.greaterThan(level.CRITICAL)) {
        return "CRITICAL";
    }
    if (ltv.greaterThan(level.POOR)) {
        return "POOR";
    }
    if (ltv.greaterThan(level.FAIR)) {
        return "FAIR";
    }
    return "HEALTHY";
}
function getLoanState(ltv, status, liquidationThreshold) {
    if (status === LoanStatus.OPENING) {
        return "PENDING";
    }
    if (status === LoanStatus.CLOSED) {
        return "PAID";
    }
    if (status === LoanStatus.FAILED) {
        return "FAILED";
    }
    return getDangerLevelForLtv(ltv, liquidationThreshold);
}
// ltv thresholds, percentages are currently hardcoded
export function getLtvValues(liquidationThreshold) {
    // internal liq ltv is 1% less than the adapter liq threshold, rounded up to the nearest half
    const liqLtv = liquidationThreshold
        .sub(BigFloat.fromNumber(0.01))
        .toDecimals(2);
    // other values are derived from the new liquidation threshold
    return {
        LIQUIDATED: liqLtv,
        CRITICAL: liqLtv.sub(BigFloat.fromNumber(0.05)),
        POOR: liqLtv.sub(BigFloat.fromNumber(0.15)),
        FAIR: liqLtv.sub(BigFloat.fromNumber(0.25)),
        HEALTHY: BigFloat.fromNumber(0),
    };
}
