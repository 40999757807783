type ButtonProps = {} & JSX.IntrinsicElements["button"];

export function SecondaryButton(props: ButtonProps) {
  return (
    <button
      type="button"
      {...props}
      className={`${props.className} min-h-component-height whitespace-nowrap rounded-[0.5rem] border border-secondary-action-border bg-white  py-[0.75rem] px-[2rem]  font-sans font-medium text-secondary-action-text hover:bg-secondary-action-focus focus:bg-secondary-action-focus active:bg-secondary-action-pressed disabled:pointer-events-none disabled:opacity-50`}
    />
  );
}
