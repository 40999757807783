type DetailProps = {
  name: string | JSX.Element;
  value: string | JSX.Element;
};

export function Detail(props: DetailProps) {
  const { name, value } = props;

  return (
    <div
      className={
        "flex flex-row justify-between p-2 text-xs even:bg-calculator-even-detail-bg"
      }
    >
      <div className="w-1/3">{name}</div>
      <div className="w-2/3 overflow-hidden whitespace-nowrap text-end font-semibold">
        {value}
      </div>
    </div>
  );
}
