"use client";

import { PropsWithChildren, useState } from "react";
import { Detail } from "./Detail";

export type LoanDetailsProps = {
  interest: number | string;
  loanAmount: string;
  loanAmountInDai?: string;
  loanValueInNok?: string;
  collateral: string;
  collateralCurrency: string;
  collateralInLoanCurrency: string;
  collateralPrice: string;
  collateralLiqPrice: string;
  currentLtv: number | string;
  maxLtv: number | string;
  liqLtv: number | string;
  loanFee?: number | string;
  padding?: string;
  daiToUsdRate?: string;
};

export function LoanDetailsContainer(
  props: LoanDetailsProps & PropsWithChildren
) {
  const [detailsHidden, setDetailsHidden] = useState(true);

  return (
    <div id="details-container" className="mt-2 flex w-full flex-col">
      <button
        id="show-details-button"
        onClick={() => setDetailsHidden(!detailsHidden)}
        className="rounded-bottom flex w-full flex-row items-center justify-start border-t bg-white p-3 pl-4 text-sm text-text hover:bg-secondary-action-focus focus:bg-secondary-action-focus"
      >
        {detailsHidden ? "⯆ show" : "⯅ hide"} details
      </button>
      {!detailsHidden && <LoanDetails padding="px-3 pb-3" {...props} />}
    </div>
  );
}

export function LoanDetails(props: LoanDetailsProps) {
  const {
    interest,
    loanAmount,
    loanValueInNok,
    loanAmountInDai,
    collateral,
    collateralCurrency,
    collateralInLoanCurrency,
    collateralLiqPrice,
    collateralPrice,
    currentLtv,
    maxLtv,
    liqLtv,
    loanFee,
    padding,
    daiToUsdRate,
  } = props;

  return (
    <div id="inner-details-container" className={"flex flex-col " + padding}>
      <Detail name="Interest rate" value={interest + "%"} />
      {loanFee && <Detail name="K33 Setup fee" value={loanFee + "%"} />}
      <Detail
        name="Loan maturity"
        value={
          <div className="flex flex-row justify-end gap-1 text-end">
            12 Months
            <p className="text-end font-normal italic ">{"(auto renewal)"}</p>
          </div>
        }
      />
      <Detail name="Loan amount" value={loanAmount} />
      {loanAmountInDai && (
        <Detail
          name={<div className="ml-3 italic">Loan issued in $DAI</div>}
          value={
            <p className="flex h-full flex-col justify-center">
              {loanAmountInDai}
            </p>
          }
        />
      )}
      {loanValueInNok && (
        <Detail
          name={<div className="ml-3 italic">Loan value in NOK</div>}
          value={
            <p className="flex h-full flex-col justify-center">
              {loanValueInNok}
            </p>
          }
        />
      )}
      <Detail
        name="Collateral"
        value={
          <div className="flex flex-row justify-end gap-1 text-end">
            {collateral} {collateralCurrency}
            <p className="text-end font-normal">
              {" "}
              ≈ {" " + collateralInLoanCurrency}
            </p>
          </div>
        }
      />
      <Detail name="Current LTV" value={currentLtv + "%"} />
      <Detail name="Max LTV" value={maxLtv + "%"} />
      <Detail name="Liquidation LTV" value={liqLtv + "%"} />
      {daiToUsdRate && <Detail name="DAI/USD rate" value={daiToUsdRate} />}
      <Detail name={collateralCurrency + " price"} value={collateralPrice} />
      <Detail
        name={collateralCurrency + " liquidation price"}
        value={collateralLiqPrice}
      />
    </div>
  );
}
