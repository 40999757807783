import { formatUnits, parseUnits } from "viem";
export class BigFloat {
    number;
    decimals;
    constructor(number, decimals) {
        this.number = number;
        this.decimals = decimals;
    }
    static parse(number, decimals) {
        return new BigFloat(parseUnits(number, decimals), decimals);
    }
    static fromNumber(number) {
        return BigFloat.fromString(number.toString());
    }
    static fromString(numberString) {
        numberString = numberString.trim();
        let decimals = 0;
        if (numberString.includes(".")) {
            const decimalIndex = numberString.indexOf(".");
            decimals = numberString.length - decimalIndex - 1;
            numberString = numberString.replace(".", "");
        }
        return new BigFloat(BigInt(numberString), decimals);
    }
    format() {
        return formatUnits(this.number, this.decimals);
    }
    formatAndRound(decimals = 2) {
        return Math.round(this.toNumber() * 10 ** decimals) / 10 ** decimals;
    }
    toDecimals(decimals) {
        const delta = decimals - this.decimals;
        if (delta === 0) {
            return this;
        }
        else if (delta > 0) {
            const number = this.number * BigInt(10) ** BigInt(delta);
            return new BigFloat(number, decimals);
        }
        else {
            const number = this.number / BigInt(10) ** BigInt(-delta);
            return new BigFloat(number, decimals);
        }
    }
    mul(other) {
        return new BigFloat(this.number * other.number, this.decimals + other.decimals);
    }
    div(other) {
        const targetDecimals = Math.max(this.decimals, other.decimals);
        const thisFloat = this.toDecimals(targetDecimals + other.decimals + targetDecimals);
        return new BigFloat(thisFloat.number / other.number, thisFloat.decimals - other.decimals);
    }
    pow(other) {
        return new BigFloat(this.number ** BigInt(other), this.decimals * Number(other));
    }
    add(other) {
        if (this.decimals === other.decimals) {
            return new BigFloat(this.number + other.number, this.decimals);
        }
        else if (this.decimals > other.decimals) {
            const number = this.number +
                other.number * BigInt(10) ** BigInt(this.decimals - other.decimals);
            return new BigFloat(number, this.decimals);
        }
        else {
            const number = this.number * BigInt(10) ** BigInt(other.decimals - this.decimals) +
                other.number;
            return new BigFloat(number, other.decimals);
        }
    }
    sub(other) {
        if (this.decimals === other.decimals) {
            return new BigFloat(this.number - other.number, this.decimals);
        }
        else if (this.decimals > other.decimals) {
            const number = this.number -
                other.number * BigInt(10) ** BigInt(this.decimals - other.decimals);
            return new BigFloat(number, this.decimals);
        }
        else {
            const number = this.number * BigInt(10) ** BigInt(other.decimals - this.decimals) -
                other.number;
            return new BigFloat(number, other.decimals);
        }
    }
    toNumber() {
        return Number(this.format());
    }
    serialize() {
        return `BigFloat:${this.number.toString()}:${this.decimals}`;
    }
    compareTo(other) {
        const targetDecimals = Math.max(this.decimals, other.decimals);
        const d1 = this.toDecimals(targetDecimals).number;
        const d2 = other.toDecimals(targetDecimals).number;
        if (d1 > d2)
            return 1;
        if (d1 === d2)
            return 0;
        // Just to make typescript happy, as this will always be true in this case.
        // if (d1 < d2)
        return -1;
    }
    equals(other) {
        return this.compareTo(other) === 0;
    }
    greaterThan(other) {
        return this.compareTo(other) === 1;
    }
    lessThan(other) {
        return this.compareTo(other) === -1;
    }
    greaterThanOrEqualTo(other) {
        return this.compareTo(other) >= 0;
    }
    lessThanOrEqualTo(other) {
        return this.compareTo(other) <= 0;
    }
    static deepSerialize(value) {
        if (value === null) {
            return null;
        }
        if (value instanceof BigFloat) {
            return value.serialize();
        }
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                return value.map((v) => BigFloat.deepSerialize(v));
            }
            return Object.fromEntries(Object.entries(value).map(([key, value]) => [
                key,
                BigFloat.deepSerialize(value),
            ]));
        }
        return value;
    }
    static deepDeserialize(value) {
        if (value === null) {
            return null;
        }
        if (value instanceof Date) {
            return value;
        }
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                return value.map((v) => BigFloat.deepDeserialize(v));
            }
            return Object.fromEntries(Object.entries(value).map(([key, value]) => [
                key,
                BigFloat.deepDeserialize(value),
            ]));
        }
        if (BigFloat.isSerializedBigFloat(value)) {
            return BigFloat.deserialize(value);
        }
        return value;
    }
    static isSerializedBigFloat(serialized) {
        if (typeof serialized !== "string") {
            return false;
        }
        return serialized.match(/^BigFloat:[0-9]+:[0-9]+$/) !== null;
    }
    static zero() {
        return new BigFloat(BigInt(0), 0);
    }
    static deserialize(serialized) {
        const [bigFloatCheck, number, decimals] = serialized.split(":");
        if (bigFloatCheck !== "BigFloat") {
            throw new Error("Invalid serialized BigFloat");
        }
        const val = new BigFloat(BigInt(number), Number(decimals));
        return val;
    }
}
