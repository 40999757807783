import { BigFloat } from "shared/build/src/utils/BigFloat";
import { AssetId } from "shared/build/src/SupportedCurrencies";
import { filterPositiveNumbers } from "shared/build/src/utils/InputFilters";

const frontalSymbols = ["USD", "CHF", "GBP", "EUR"];

export const isFrontalSymbol = (currency: AssetId) =>
  frontalSymbols.includes(currency);

export const currencySymbols: Record<Exclude<AssetId, "DAI">, string> = {
  NOK: "kr",
  SEK: "kr",
  DKK: "kr",
  USD: "$",
  EUR: "€",
  CHF: "₣",
  GBP: "£",
  ETH: "ETH",
  wBTC: "BTC",
};

/**
 * Reads the format type from a dictionary of AssetId's and checks whether the symbol goes in front or not.
 * Special formatting for DAI."
 * @param currency The AssetId to choose the format for.
 * @param value The value to affix the currency to.
 * @returns The formatted value.
 */
export function formatCurrency(currency: AssetId, value?: string) {
  if (currency === "DAI") {
    return "$" + value + " " + "DAI";
  }

  if (isFrontalSymbol(currency)) {
    return currencySymbols[currency] + value;
  }

  return value + " " + currencySymbols[currency];
}

export function formatToDisplayStringWithCurrency(
  currency: AssetId,
  value: string | number | BigFloat
) {
  return formatCurrency(currency, formatToDisplayString(value));
}

// filters for numbers and replaces any "," in input with "."
export function formatFromDisplayString(value: string) {
  return filterPositiveNumbers(value.replace(",", "."));
}

export function formatToDisplayString(
  value?: string | number | BigFloat,
  formatDecimals: boolean = true
) {
  if (value === 0) return "0";

  if (!value) return "";

  // default, formats non-string value to 2 decimal points
  if (typeof value !== "string") {
    if (typeof value === "number") {
      value = value.toFixed(2);
    } else {
      value = value.formatAndRound().toString();
    }
  }

  return formatDisplayString(value, formatDecimals);
}

function formatDisplayString(value: string, formatDecimals: boolean) {
  try {
    value = formatFromDisplayString(value);

    // prevents user from writing 0 at the start of a whole number
    value = removeLeadingZeros(value);

    // removes extra decimal points in favor of first occurence
    if (value.split(".").length > 2) {
      value = removeExtraDecimalPoints(value);
    }

    const hasDecimals = value.split(".").length == 2;

    const integralPart = formatIntegralPart(
      hasDecimals ? value.split(".")[0] : value
    );

    const decimalPart = (() => {
      if (formatDecimals) {
        return "." + formatDecimalPart(value.split(".")[1]);
      }

      return hasDecimals ? value.substring(value.indexOf(".")) : "";
    })();

    return integralPart + decimalPart;
  } catch {
    return "0";
  }
}

// adds proper spacing
function formatIntegralPart(integralPart: string) {
  let formattedIntegralPart = "";

  let count = 0;

  for (let i = integralPart.length - 1; i >= 0; i--) {
    formattedIntegralPart = integralPart[i] + formattedIntegralPart;

    count++;

    if (count % 3 === 0 && i !== 0) {
      formattedIntegralPart = " " + formattedIntegralPart;
    }
  }

  return formattedIntegralPart;
}

// makes sure that numbers always end in 2 decimals
function formatDecimalPart(decimalPart?: string) {
  if (!decimalPart) {
    return "00";
  }

  if (decimalPart.length == 2) return decimalPart;

  if (decimalPart.length > 2) {
    const parsedNum = BigFloat.fromString("0." + decimalPart);

    // if bigfloat rounds down to an int
    return formatDecimalPart(
      parsedNum.formatAndRound().toString().split(".")[1]
    );
  }

  return decimalPart + "0";
}

function removeLeadingZeros(value: string) {
  const zeroLeadsWholeNumber =
    value.startsWith("0") && value[1] && value[1] != ".";

  if (zeroLeadsWholeNumber) {
    value = value.substring(value.indexOf("0") + 1);
    return removeLeadingZeros(value);
  }

  return value;
}

function removeExtraDecimalPoints(value: string) {
  const subStrBeforeDecPoint = value.split(".")[0];
  let subStrAfterDecPoint = value.split(".")[1];

  subStrAfterDecPoint = subStrAfterDecPoint.replaceAll(".", "");

  return subStrBeforeDecPoint + "." + subStrAfterDecPoint;
}
